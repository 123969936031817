import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { DataPropsPage } from "../contracts/data-props-page"
import {
  LibraryIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  HomeIcon,
} from "@heroicons/react/solid"

const Kontakt: React.FC<PageProps<DataPropsPage>> = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout title={post.frontmatter.title} location={location}>
      <Seo title={post.frontmatter.title} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <section>
          <p>
            Parafia Rzymskokatolicka pw. Wniebowzięcia Najświętszej Maryi Panny
            w Wielogłowach
          </p>
          <a class="link-text-gray" href="/img/Standardy-Parafialne-Parafia-w-Wielogłowach.pdf" target="_blank">Standardy Parafialne Parafia w Wielogłowach</a>
          <div className="flex flex-row my-6">
            <LocationMarkerIcon
              className={"text-gray-600 mr-4 mt-0.5 h-5 w-5"}
            ></LocationMarkerIcon>{" "}
            <div>
              Wielogłowy 4, 33-311 Wielogłowy (
              <a
                className="link-text-gray"
                href="https://goo.gl/maps/SENrk3d6kjDxPzsc8"
                target="_blank"
              >
                wskazówki dojazdu
              </a>
              )
            </div>
          </div>
          <div className="flex flex-row my-6">
            <PhoneIcon
              className={"text-gray-600 mr-4 mt-1 h-5 w-5"}
            ></PhoneIcon>
            <div className="flex flex-col">
              <a className="link-text-gray" href="tel:+48184432182">
                +48 18 44 32 182
              </a>
              <a className="link-text-gray" href="tel:+48184400047">
                +48 18 44 00 047
              </a>
            </div>
          </div>
          <div className="flex flex-row align-center my-6">
            <MailIcon className={"text-gray-600 mr-4 h-5 w-5"}></MailIcon>
            <a
              className="link-text-gray"
              href="mailto:wieloglowy@diecezja.tarnow.pl"
            >
              wieloglowy@diecezja.tarnow.pl
            </a>
          </div>
          <div className="flex flex-row my-6">
            <HomeIcon className={"text-gray-600 mr-4 mt-1 h-5 w-5"}></HomeIcon>
            <div className="flex flex-col">
              <span>Godziny otwarcia kancelarii</span>
              <span>
                Poniedziałek - Sobota <strong>15:00 - 17:30</strong>
              </span>
            </div>
          </div>
          <div className="flex flex-row my-6">
            <LibraryIcon
              className={"text-gray-600 mr-4 mt-1 h-5 w-5"}
            ></LibraryIcon>
            <div className="flex flex-col">
              <span>Bank Spółdzielczy w Nowym Sączu</span>
              <span>82 8811 0006 0000 0040 3032 0001</span>
            </div>
          </div>
          <div
            className="gatsby-resp-iframe-wrapper"
            style={{
              paddingBottom: `75%`,
              position: `relative`,
              height: 0,
              overflow: `hidden`,
              marginBottom: `1rem`,
            }}
          >
            <iframe
              src="https://www.google.com/maps/d/embed?mid=12AVMSh8wvYixr-dwYL-z04Y7AphDnFCc"
              style={{
                position: `absolute`,
                top: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
              }}
            ></iframe>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default Kontakt

export const query = graphql`
  {
    markdownRemark(frontmatter: { layout: { eq: "kontakt" } }) {
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
      }
    }
  }
`
